import { isMobile } from 'react-device-detect'
import { useEffect, useState, use } from 'react'

export default function () {
  const [dots, setDots] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setDots(dots => dots === 3 ? 0 : dots + 1);
    }, 500)

    return () => clearInterval(interval);
  }, [])

  return (
    <div className="flex w-full h-full justify-center" style={{
      height: isMobile ? 'calc(100vh - 10.6rem)' : 'calc(100vh - 14.6rem)'
    }}>
      <div className="self-center text-2xl lg:text-8xl font-text font-bold">
        Loading {(new Array(dots).fill(null)).map((item, index) => (<span key={index}>.</span>))}
      </div>
    </div>
  )
}