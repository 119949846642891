import { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import classnames from 'classnames';
import {
  isBrowser,
  isMobile
} from "react-device-detect";

export default function () {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState([-1, -1]);
  const history = useHistory();
  const location = useLocation();
  const sections = [
    {
      title: "Home",
      path: '/'
    },
    // {
    //   title: "Contact",
    //   path: '/home#contact'
    // },
    {
      title: "About",
      children: [
        {
          title: 'Author’s Notes',
          path: '/authors-notes'
        },
        {
          title: 'Table of Contents',
          path: '/table-of-contents'
        },
        {
          title: 'Book Sections',
          path: '/book-sections'
        },
        {
          title: 'Pioneers of Modern Science',
          path: '/pioneers-of-modern-science'
        },
        {
          title: 'Subtle Concepts',
          path: '/subtle-concepts'
        },
        // {
        //   title: 'Sample Pages',
        //   path: '/sample-pages'
        // },
      ]
    },
    {
      title: 'Buy Now',
      link: 'https://payhip.com/b/4tB6'
    }
  ];
  const sectionsMobile = [
    {
      title: "Home",
      path: '/'
    },
    {
      title: 'Author’s Notes',
      path: '/authors-notes'
    },
    {
      title: 'Table of Contents',
      path: '/table-of-contents'
    },
    {
      title: 'Book Sections',
      path: '/book-sections'
    },
    {
      title: 'Pioneers of Modern Science',
      path: '/pioneers-of-modern-science'
    },
    {
      title: 'Subtle Concepts',
      path: '/subtle-concepts'
    },
    {
      title: 'Buy Now',
      link: 'https://payhip.com/b/4tB6'
    }
  ];

  const getCurrentLocation = () => {
    const currentSections = isMobile ? sectionsMobile : sections;
    let currentSelected;
    for (let i = 0; i < currentSections.length; ++i) {
      if (currentSections[i].children) {
        for (let j = 0; j < currentSections[i].children.length; ++j) {
          if (currentSections[i].children[j].path === location.pathname) {
            currentSelected = [i, j];
            break;
          }
        }
      } else {
        if (currentSections[i].path === location.pathname) {
          currentSelected = [i, -1];
          break;
        }
      }
    }
    if (currentSelected) {
      setSelected(currentSelected)
      if (selected[0] !== -1) {
        document.querySelector('title').innerHTML = `A Fresh Look At Genesis 1 - 2 | ${currentSelected[1] !== -1 ? currentSections[currentSelected[0]].children[currentSelected[1]].title : currentSections[currentSelected[0]].title}`
      }
    }
  }

  useLayoutEffect(getCurrentLocation, [location.pathname]);


  useEffect(getCurrentLocation, [])

  const onClick = (i, j) => {
    if (!sections[i].children) {
      setSelected([i, j]);
    }
    if (sections[i].link) {
      window.location = sections[i].link
      return;
    }
    if (sections[i].children && j !== -1) {
      history.push(sections[i].children[j].path)
    } else {
      history.push(sections[i].path)
    }
  }

  const onClickMobile = (i, j) => {
    setSelected([i, j]);
    if (sectionsMobile[i].link) {
      window.location = sectionsMobile[i].link
      return;
    }
    setOpened(sectionsMobile[i].children && j === -1 ? true : false);
    if (sectionsMobile[i].children && j !== -1) {
      history.push(sectionsMobile[i].children[j].path)
    } else {
      history.push(sectionsMobile[i].path)
    }
  }

  return (
    <div>
      <nav className="bg-primary">
        <div className="flex py-4 justify-between lg:justify-center">
          <div
            className="text-title-accent text-xl lg:text-4xl lg:px-24 px-4 py-0 font-headers-bg font-semibold lg:w-full lg:m-4 lg:title-letter-spacing"
          >
            <span className="lg:text-5xl text-2xl">A </span>
            <span className="lg:text-5xl text-2xl">F</span>RESH
            <span className="lg:text-5xl text-2xl"> L</span>OOK <br />
            <sup className="pl-4 z-0">AT </sup>
            <span className="lg:text-5xl text-2xl">G</span>ENESIS 1-2
          </div>
          <div
            className="hidden self-end lg:flex text-secondary space-x-20 font-text font-bold pr-36 col-span-9 justify-center w-full text-xl"
          >
            {
              sections.map((item, index) => (
                <div key={`desktop-item-${index}`} className={classnames('group', {
                  'navbar-items-selected': selected[0] === index,
                  'navbar-items': !item.children,
                  'navbar-items-parent': item.children
                })} onClick={() => onClick(index, -1)}>
                  {item.title}
                  {
                    item.children && <div
                      className="absolute z-40 text-primary w-1/7 fade-in opacity-0 group-hover:pointer-events-auto pointer-events-none group-hover:opacity-100 hover:translate-y-0"
                      style={{
                        marginTop: 14,
                        marginLeft: -16
                      }}>
                      {
                        item.children.map((child, childIndex) => (
                          <div key={`child-${childIndex}`} className={classnames(
                            `p-3 
                            transition-colors 
                            duration-200 
                            ease-in-out 
                            bg-secondary 
                            hover:text-secondary
                            hover:bg-text
                            cursor-pointer
                            border-primary`,
                            { 'border-t': childIndex != 0, 'bg-text text-secondary': childIndex === selected[1] }
                          )} onClick={() => onClick(index, childIndex)}>
                            {child.title}
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              ))
            }
          </div>
          <div className="lg:hidden self-center pr-4">
            <button className="p-4 focus:outline-none" onClick={() => {
              setOpened(!opened);
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-8 text-secondary"
              >
                {
                  opened
                    ? <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                    : <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                }
              </svg>
            </button>
          </div>
        </div>
      </nav >

      <div
        className={classnames(
          'bg-primary text-secondary text-center absolute z-20 w-screen opacity-0 transform fade-in lg:hidden pointer-events-none',
          {
            'opacity-100 translate-y-0 pointer-events-auto': opened
          }
        )}
      >
        {
          sectionsMobile.map((item, index) => (
            <div
              key={`mobile-item-${index}`}
              className={classnames(
                'relative border-b border-1 border-opacity-50 border-title-accent py-4 font-bold',
                {
                  'border-t ': index === 0,
                  'bg-secondary text-primary': index === selected[0]
                }
              )}
              onClick={() => onClickMobile(index, -1)}
            >
              {
                item.children &&
                <div className={classnames(
                  "absolute text-secondary transform right-4 top-5",
                  { 'text-text': index === selected[0] }
                )}>
                  {index === selected[0]
                    ? <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  }
                </div>
              }
              {item.title}
              {
                item.children && (
                  <div className={classnames(
                    "transition-all duration-200 pt-4 text-secondary",
                    {
                      'block': index === selected[0],
                      'hidden': index !== selected[0]
                    }
                  )}>
                    {
                      item.children.map((subItem, subIndex) => (
                        <div key={`subitem-mobile-${subIndex}`} className={classnames(
                          "border-b border-1 border-opacity-50 border-title-accent py-4 bg-primary font-normal",
                          {
                            'border-t ': subIndex === 0,
                            '-mb-4 border-b-0': subIndex === item.children.length - 1,
                            'bg-secondary text-primary': subIndex === selected[1]
                          }
                        )} onClick={(e) => { e.stopPropagation(); onClickMobile(index, subIndex) }}>
                          {subItem.title}
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>
          ))
        }

      </div>

      <div
        className={classnames(
          'absolute w-full bg-black z-10 opacity-0 transform fade-in lg:hidden pointer-events-none overflow-y-hidden',
          {
            'opacity-80 translate-y-0 pointer-events-auto': opened
          }
        )} style={{ height: 'calc(100vh - 6rem)' }} onClick={() => setOpened(false)}
      ></div>
    </div >
  );
}