export default function () {
  return (
    <div className="grid grid-cols-2">
      <div className="border-black text-text font-bold hidden lg:block text-center lg:text-left py-6 lg:px-36" style={{
        borderTopWidth: '0.1em'
      }}>
        <strong>Contact: </strong> {`info<@>a-fresh-look-at-genesis.org`}
      </div>
      <div className="border-black text-text font-bold text-center col-span-2 lg:col-span-1 lg:text-right py-6 lg:px-36" style={{
        borderTopWidth: '0.1em'
      }}>
        Copyright © 2020, 2021, 2024 by Río Pindo Publishing, LLC. All rights reserved.
      </div>
    </div>
  );
}