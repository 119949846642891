import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { lazy, Suspense, useLayoutEffect } from 'react';
import Loading from './pages/Loading';

const Home = lazy(() => import('./pages/home'))
const Pioneers = lazy(() => import('./pages/pioneers'))
const BookSections = lazy(() => import('./pages/book_sections'))
const SubtleConcepts = lazy(() => import('./pages/subtle_concepts'))
const AuthorsNotes = lazy(() => import('./pages/authors_notes'))
const TableOfContents = lazy(() => import('./pages/table_of_contents'))
// const SamplePages = lazy(() => import('./pages/sample_pages'))

export default function () {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.pathname, location.hash]);

    return (
        <Switch>
            <Suspense fallback={<Loading />}>
                <Route path="/pioneers-of-modern-science" exact>
                    <Pioneers />
                </Route>
                <Route path="/authors-notes" exact>
                    <AuthorsNotes />
                </Route>
                <Route path="/book-sections" exact>
                    <BookSections />
                </Route>
                <Route path="/subtle-concepts" exact>
                    <SubtleConcepts />
                </Route>
                <Route path="/table-of-contents" exact>
                    <TableOfContents />
                </Route>
                <Route path="/" exact>
                    <Home />
                </Route>
            </Suspense>
        </Switch>
    );
}
