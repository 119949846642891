import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './router';

import NavBar from './layout/NavBar'
import Footer from './layout/Footer'

function App() {
    window.decodeHtml = function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    window.decodeHtmlContent = function (html) {
        const aux = html.replaceAll('\n', '<br/>')
        return aux.substring(0, aux.length - 5)
    }

    return (
        <Router>
            <div className="bg-primary-background font-text">

                <NavBar />
                <Routes />
                <Footer />

            </div>
        </Router>
    );
}

export default App;
